<template>
    <header>
        <div class="logo_box">
            <h1 class="logo">
                <template v-if="userInfo.logo">
                    <img :src="userInfo.logo" height="100%" style="max-width: 110px;">
                </template>
                <template v-else>
                    {{ userInfo.name }}
                </template>
            </h1>
        </div>
    </header>
    
    <div class="container">
        <main class="card">
            
            <!-- begin: 주문완료 -->
            <div class="order_complete">
                <div class="complete_msg">
                    주문이 완료되었습니다.<br />
                    감사합니다.
                </div>
                <button type="button" class="yellow_btn" @click="onClickFinish()">확인</button>
            </div>
            <!-- end: 주문완료 -->
            
            <!-- begin : 상품정보 list -->
            <div class="order_total">
                <div class="order_box">
                    <div class="product_box_price_total">
                        <div class="product_box_price_total_inner">
                            <span>총 결제금액</span>
                            <strong class="product_box_price_total_number total">{{ orderData.amount?.toLocaleString() }}원</strong>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end : 상품정보 list -->
            
            <!-- begin : 입금계좌 안내 -->
            <div class="account_ar">
                <h2>입금계좌 안내</h2>
                <div class="account">
                    <form autocomplete="off">
                    <ul v-for="account in accountList" :key="account.seq">
                        <li><span class="label label_blue">입금계좌</span></li>
                        <li><span>{{ account.bankName }}<small>({{ account.accountName }})</small></span> <strong>{{ account.account }}</strong></li>
                        <li><button type="button" class="btn-clipboard" @click="onClickAccountClipboard(account.account)">계좌번호 복사</button></li>
                    </ul>
                    </form>
                </div>
            </div>
            <!-- end : 입금계좌 안내 -->

            <!-- begin : 배송방법 -->
            <div class="delivery_info">
                <h2>배송방법</h2>
                <div class="delivery_msg_box">
                    <p>{{ deliveryType }}</p>
                    <ul>
                        <li><strong>주문자 : {{ orderData.name }}</strong> <span>{{ orderData.phone }}</span></li>
                        <li v-if="orderData.memo" v-html="orderData.memo"></li>
                    </ul>
                </div>
            </div>
            <!-- end : 배송방법 -->
             
        </main>
        <footer>
            <strong>상호명 : {{ userInfo.name }}</strong>
            <ul>
                <li v-if="userInfo.ceoName">대표이사 : {{ userInfo.ceoName }}</li>
                <li v-if="userInfo.address">주소: {{ userInfo.address }} {{ userInfo.addressDetail }}</li>
                <li v-if="userInfo.phone">Tel: {{ userInfo.phone }}</li>
                <li v-if="userInfo.businessNumber">사업자등록번호 : {{ userInfo.businessNumber }}</li>
                <li v-if="userInfo.mailOrderSalesRegistrationNumber">통신판매업신고 : {{ userInfo.mailOrderSalesRegistrationNumber }}</li>
                <li v-if="userInfo.businessNumber"><a :href="'https://www.ftc.go.kr/bizCommPop.do?wrkr_no='+onlyNumbers(userInfo.businessNumber)" target="_blank">사업자정보확인</a></li>
            </ul>
            <p>© 2024 골드오더</p>
        </footer>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted  } from 'vue'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { Clipboard } from "v-clipboard";

export default defineComponent({
    name : 'shopOrderCompleted',
    setup() {

        const { $axiosConnect, router } = commonMixin()

		const shopData     = ref({})
		const userInfo     = ref({})
        const orderData    = ref({})
		const token        = ref(null)
		const websiteTitle = ref(null)
		const accountList  = ref([])
		const deliveryType = ref(null)

		onMounted(() => {
			const completed = JSON.parse(sessionStorage.getItem("completed"));
            if (completed) {
                window.scrollTo(0, 0);
                orderData.value    = completed.orderData;
                token.value        = completed.token;
				deliveryType.value = orderData.value.orderProduct[0].deliveryType
				sessionStorage.removeItem("completed");
				onLoadSaleShop()
            }else{
				router.go(-1);
			}
		});

		const onLoadSaleShop = async () => {
            shopData.value = await $axiosConnect(`/sale/shop/${token.value}`, 'GET' )

            if(shopData.value.businessType === 'SHOP'){

                //ownerUserId정보 가져오기 - 계좌번호, 은행 등
                const memberInfo = await $axiosConnect(`/api/member/id/${shopData.value.ownerUserId}`, 'GET')
                if(memberInfo){
                    accountList.value = memberInfo.body.list.memberAccount
                    userInfo.value    = memberInfo.body.list
                }
                websiteTitle.value = userInfo.value.name

            }else{
                console.log("잘못된 접근입니다.")
				//alert("잘못된 접근입니다.")
                //router.go(-1)
                return
            }
        }

		const onlyNumbers = (str) => {
            if(!str){
                return ''
            }
            return str.replace(/\D/g, '');
        }

        const onClickAccountClipboard = (number) => {
            Clipboard.copy(number);
        }

        const onClickFinish = () => {
            router.push('/SHOP?token='+token.value);
        }
        
        return {
			websiteTitle,
			shopData,
            accountList,
			userInfo,
            orderData,
			deliveryType,
			onlyNumbers,
            onClickAccountClipboard,
            onClickFinish
        }

    }
})
</script>
<style scoped>
@import "../assets/style/style.css";
</style>