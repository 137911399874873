<template>
    <div></div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name : 'defaultFooterPage',
    setup() {
		//
    }
});
</script>