<template>
    <default-header></default-header>
    <router-view></router-view>
    <default-footer></default-footer>
</template>

<script>
import { defineComponent } from 'vue';

//components
import DefaultHeader from '@/layouts/inc/DefaultHeader';
import DefaultFooter from '@/layouts/inc/DefaultFooter';

export default defineComponent({
    name       : 'defaultLayoutPage',
    components : {
        'default-header': DefaultHeader,
        'default-footer': DefaultFooter
    }
});
</script>