import { createRouter, createWebHistory } from 'vue-router';

import DefaultLayoutPage from '@/layouts/DefaultLayout';

import retailPage from '@/views/retail';

import wholesalePage from '@/views/wholesale';

import shopPage from '@/views/shop';
import shopOrderCompleted from '@/views/shopOrderCompleted';

import errorPage from '@/views/retail';

// 모든 라우트를 배열로 정의합니다.
const routes = [
    {
        path: '/',
        name: 'defaultLayoutPage',
        components: {
            default: DefaultLayoutPage
        },
        children: [
            //MAIN
            {
                path: '/RETAIL',
                name: 'retailPage',
                components: {
                    default: retailPage
                },
                meta: {
                    title  : '주문은 여기를 클릭하세요.',
                    ico    : '/favicon2.ico',
                    ogImage: '/goldorder2.png'
                }
            },
            {
                path: '/WHOLESALE',
                name: 'wholesalePage',
                components: {
                    default: wholesalePage
                },
                meta: {
                    title  : '주문은 여기를 클릭하세요',
                    ico    : '/favicon2.ico',
                    ogImage: '/goldorder2.png'
                }
            },
            {
                path: '/SHOP',
                name: 'shopPage',
                components: {
                    default: shopPage
                },
                meta: {
                    title  : '주문은 여기를 클릭하세요',
                    ico    : '/favicon2.ico',
                    ogImage: '/goldorder2.png'
                }
            },
            {
                path: '/SHOP/COMPLETED',
                name: 'shopOrderCompleted',
                components: {
                    default: shopOrderCompleted
                },
                meta: {
                    title  : '주문은 여기를 클릭하세요',
                    ico    : '/favicon2.ico',
                    ogImage: '/goldorder2.png'
                }
            },
            {
                path: '404',
                name: 'errorPage',
                components: {
                    default: errorPage
                }
            },
        ]
    },
];

// VueRouter 인스턴스를 생성합니다.
const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title; // 페이지 타이틀 변경

    const icon    = document.querySelector('link[rel="icon"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogImage = document.querySelector('meta[property="og:image"]');
    const origin  = window.location.origin;

    if (icon) {
        icon.href = to.meta.ico;
    }
    if (ogTitle) {
        //console.log("ogTitle:", ogTitle)
        ogTitle.content = to.meta.title;
    }
    if (ogImage) {
        //console.log("ogImage:", ogImage)
        ogImage.content = origin + to.meta.ogImage;
    }
    next();
});

// 생성한 router를 내보냅니다.
export default router;