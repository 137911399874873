import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'
import HttpRequest from './helper/plugins/httpRequest'
import Clipboard from 'v-clipboard'

const app = createApp(App);
app.use(Router);
app.use(Store);
app.use(HttpRequest);
app.use(Clipboard);
app.mount('#app');