import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

export default createStore({

    plugins: [
        // Vuex 상태를 세션 스토리지에 저장하는 플러그인을 추가합니다.
        createPersistedState({
            storage: window.sessionStorage,  // 세션 스토리지 사용
            removeItemOnceOnSessionExpired: true  // 세션이 만료될 때 해당 아이템을 삭제
        })
    ]
});