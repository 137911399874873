import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const commonMixin = () => {
    const store         = useStore();
    const router        = useRouter();
    const $axiosConnect = getCurrentInstance().appContext.config.globalProperties.$axiosConnect;

    return {
        store,
        router,
        $axiosConnect,
    };
};

export default commonMixin;