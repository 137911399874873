<template>
    <div>
        
        <div class="container">
            <header>
                <div class="logo_box">
                    <h1 class="logo group_logo"><img src="@/assets/group_logo.png"></h1>
                </div>
                <div class="notice_box">
                    <div class="notice_title">
                        <i class="fa fa-commenting-o" aria-hidden="true"></i>
                        <span>공지사항</span>
                    </div>
                    <div class="notice_content">
                        <div v-html="formattedNotice(shopData?.notice)"></div>
                    </div>
                </div>
                <div class="today_box group_bg">{{ today }}</div>

            </header>
            <main>

                <!--상품-->
                <div>
                    <ul class="product_list">
                        <template v-for="product in productData" :key="product.seq">
                            <li>
                                <div class="product_box">
                                    <h4 class="product_box_name">{{ product.name }}</h4>
                                    <div class="product_box_set">
                                        <div class="product_box_price">{{ product.unitPrice.toLocaleString() }}원</div>
                                        <div class="product_box_count">
                                            <div class="spinner_box">
                                                <button class="calc_button decrement" @click="onClickquantityDown(product)">-</button>
                                                <input type="number" min="0" v-model="product.quantity" readonly>
                                                <button class="calc_button increment" @click="onClickquantityUp(product)">+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product_box_delivery">
                                        배송방법 : {{ product.deliveryType }} {{ product.deliveryPayType?'(' + product.deliveryPayType + ')':'' }}
                                    </div>
                                    <div class="product_box_price">
                                        <div class="product_box_price_inner">
                                            <div>주문금액</div>
                                            <div class="product_box_price_number">{{ product.amount.toLocaleString() }}원</div>
                                        </div>
                                        <div class="product_box_price_inner">
                                            <div>배송금액</div>
                                            <div class="product_box_price_number">{{ product.deliveryAmount.toLocaleString() }}원</div>
                                        </div>
                                        <div class="product_box_price_inner">
                                            <div>합계</div>
                                            <div class="product_box_price_number">{{ product.sumAmount.toLocaleString() }}원</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>

                <!--총결제 금액-->
                <template v-if="productData.length > 1">
                    <hr class="divide" />
                    <div class="order_box">
                        <div class="product_box_price_total">
                            <div class="product_box_price_total_title">
                                결제금액
                            </div>
                            <div class="product_box_price_total_inner">
                                <div>총 주문금액</div>
                                <div class="product_box_price_total_number">{{ productTotal.toLocaleString() }}원</div>
                            </div>
                            <div class="product_box_price_total_inner">
                                <div>총 배송금액</div>
                                <div class="product_box_price_total_number">{{ deliveryTotal.toLocaleString() }}원</div>
                            </div>
                            <div class="product_box_price_total_inner">
                                <div>총 결제금액</div>
                                <div class="product_box_price_total_number">{{ allTotal.toLocaleString() }}원</div>
                            </div>
                        </div>
                    </div>
                </template>

                <!--주문서-->
                <hr class="divide" />
                <div class="order_box">
                    <h4>주&nbsp;&nbsp;문&nbsp;&nbsp;서</h4>
                    <div class="order_input_box">
                        <form autocomplete="off">
                            <div class="tr_row">
                                <div class="tr_row_th">주문자(닉네임)<span>*</span></div>
                                <div class="tr_row_td"><input type="text" name="name" v-model="orderData.name"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th">주소<span>*</span></div>
                                <div class="tr_row_td"><input type="text" name="address" v-model="orderData.address"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th">연락처<span>*</span></div>
                                <div class="tr_row_td"><input type="tel" name="phone" placeholder="- 없이 숫자만 입력해주세요" v-model="orderData.phone"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th">요청사항</div>
                                <div class="tr_row_td"><textarea name="memo" ref="memo" v-model="orderData.memo" style="height: 40px;"></textarea></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th">입금자명<span>*</span></div>
                                <div class="tr_row_td"><input type="text" name="depositorName" v-model="orderData.depositorName"></div>
                            </div>
                            <!--
                            <div class="tr_row">
                                <div class="tr_row_th">결제방법<span>*</span></div>
                                <div class="tr_row_td">
                                    <select v-model="orderData.payment">
                                        <option>계좌이체</option>
                                    </select>
                                </div>
                            </div>
                            -->
                        </form>
                    </div>
                </div>

                <!--입금안내-->
                <hr class="divide" />
                <div class="order_box">
                    <h4>입&nbsp;&nbsp;금&nbsp;&nbsp;안&nbsp;&nbsp;내</h4>
                    <div class="order_input_box">
                        <form autocomplete="off">
                            <div class="tr_row">
                                <div class="tr_row_th">계좌번호</div>
                                <div class="tr_row_td">
                                    <span style="padding: 5px 0; display: block; font-weight: 300; font-size: 20px; color: blue;">{{ bankName }}&nbsp; &nbsp; &nbsp;{{ account }}
                                        <button type="button" class="btn-clipboard" @click="onClickAccountClipboard">계좌번호 복사</button>
                                    </span>
                                </div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th">예금주</div>
                                <div class="tr_row_td"><span style="padding: 5px 0; display: block; font-weight: 300; font-size: 20px; color: blue;">{{ accountName }}</span></div>
                            </div>
                        </form>
                    </div>
                </div>
                <hr class="divide" />
                <div class="button-box"><button type="button" class="order-button" @click="onClickSubmit">주문하기</button></div>
            </main>
            <footer>
                <p class="text-center">&copy; 2024 {{ websiteTitle }}</p>
            </footer>
        </div>


    </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import * as dateUtils from '@/helper/utils/dateUtils.js'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { Clipboard } from "v-clipboard";

export default defineComponent({
    name : 'wholesalePage',
    components: {},
    setup() {
        const { $axiosConnect } = commonMixin()

        const token           = ref(null)
        const websiteTitle    = ref(null)
        const today           = computed(() => dateUtils.convertDateToKoreanData())
        const shopData        = ref({})

        const account         = ref(null)
        const accountName     = ref(null)
        const bankName        = ref(null)

        const productData     = ref({})
        const orderData       = ref({})

        const productTotal    = ref(0)
        const deliveryTotal   = ref(0)
        const allTotal        = ref(0)

        onMounted(() => {
            document.title = '주문은 여기를 클릭하세요.'

            // URL에서 토큰 값 추출
            const urlParams = new URLSearchParams(window.location.search)
            const urlToken  = urlParams.get('token')

            if(urlToken){
                token.value = urlToken
                onLoadSaleShop()
            }else{
                alert("접근이 제한되었습니다.")
                //window.history.go(-1)
                return
            }
        })

        const onLoadSaleShop = async () => {
            shopData.value = await $axiosConnect(`/sale/shop/${token.value}`, 'GET', )

            //console.log("shopData.value:", shopData.value)
            //미친공구-고정값
            //shopData.value.role          - 'ADMIN'
            //shopData.value.businessType  - 'WHOLESALE'

            if(shopData.value && shopData.value.role === 'ADMIN' && shopData.value.businessType === 'WHOLESALE'){
                websiteTitle.value = '미친공구'

                //ownerUserId정보 가져오기 - 계좌번호, 은행 등
                const memberInfo = await $axiosConnect(`/member/id/${shopData.value.ownerUserId}`, 'GET', )
                if(memberInfo){
                    account.value     = memberInfo.account
                    accountName.value = memberInfo.accountName
                    bankName.value    = memberInfo.bankName
                }

                if(shopData.value.seq){
                    //상품정보 가져오기
                    productData.value = await $axiosConnect(`/productInSale/get/${shopData.value.seq}`, 'GET')

                    //console.log("productData.value:", productData.value)

                    productData.value.forEach(item => {
                        item.quantity = 0 //주문갯수
                        item.amount   = 0 //주문갯수 * unitPrice = 주문금액

                        item.deliveryAmount = 0  //배송금액
                        item.sumAmount      = 0  //주문금액 + 배송금액

                        item.inventory = item.migongAvailableStock - item.migongSaleCount   //재고
                    })

                }else{
                    alert("상품을 가져오면서 오류가 발생했습니다.")
                    window.history.go(-1)
                    return
                }
            }else if(shopData.value && shopData.value.role === 'ADMIN' && shopData.value.businessType === 'RETAIL'){
                location.href="https://goldcarriage.kr/RETAIL?token=" + token.value
            }else{
                alert("잘못된 접근입니다.")
                window.history.go(-1)
                return
            }

        }

        const formattedNotice = (text) => {
            if (!text) return ''; // 빈 문자열인 경우 처리
            return text.replace(/\n/g, '<br>'); // 개행 문자(\n)를 HTML 줄 바꿈 태그(<br>)로 변환하여 반환
        }

        const onClickquantityDown = (product) => {
            if (product.quantity > 0) {
                product.quantity = product.quantity - (product.unitQuantity * 1)
                onProductAmount(product)
            }
            
            onProductAllTotal()
        }

        const onClickquantityUp = (product) => {
            if (product.quantity >= 0) {
                if(product.inventory > 0 && product.inventory - product.quantity > 0){
                    product.quantity = product.quantity + (product.unitQuantity * 1)
                    onProductAmount(product)
                }
            }
            onProductAllTotal()
        }

        const onChangequantity = (product) => {

            if (product.quantity >= 0) {
                if(product.inventory > 0 && product.inventory - product.quantity > 0){
                    onProductAmount(product)
                    onProductAllTotal()
                }else{
                    alert("현재 주문 가능 수량은 " + product.inventory + "개 입니다.")
                    product.quantity = 0
                    return
                }
            }
            
        }

        /*
         * 각 상품별 금액 계산
         */
         const onProductAmount = (product) => {
            //상품별 계산
            product.amount         = product.quantity * product.unitPrice
            product.deliveryAmount = product.quantity > 0 ? product.deliveryFee * Math.ceil(product.quantity / 1) : 0
            product.sumAmount      = product.amount + product.deliveryAmount
        }

        /*
         * 총 결제 금액 계산
         */
        const onProductAllTotal = () => {
            productTotal.value = deliveryTotal.value = allTotal.value = 0
            productData.value.forEach(product => {
                productTotal.value  += product.amount
                deliveryTotal.value += product.deliveryAmount
                allTotal.value      += product.sumAmount
            })
        }

        const onClickSubmit = async () => {

            if(productTotal.value < 1){
                alert("주문하실 상품의 수량을 선택해주세요.");
                return;
            }

            orderData.value.name          = orderData.value.name?.trim()
            orderData.value.address       = orderData.value.address?.trim()
            orderData.value.phone         = orderData.value.phone?.trim()
            orderData.value.memo          = orderData.value.memo?.trim()
            orderData.value.depositorName = orderData.value.depositorName?.trim()
            
            if (!orderData.value.name || 
                !orderData.value.address || 
                !orderData.value.phone || 
                !orderData.value.depositorName
            ) {
                // || !orderData.value.payment
                    alert("주문서 정보를 입력해주세요.")
                    return;
            }

            if (!isValidPhoneNumber(orderData.value.phone)) {
                alert("유효한 전화번호를 숫자로 입력하세요.");
                return;
            }

            orderData.value.orderProduct  = productData.value.filter(prod => prod.amount > 0)
            orderData.value.amount        = allTotal.value  //총 결제금액
            orderData.value.token         = token.value
            orderData.value.status        = '주문'
            orderData.value.ownerId       = shopData.value.ownerUserId
            orderData.value.ownerName     = shopData.value.ownerUserName
            orderData.value.saleEndDate   = shopData.value.saleEndDate
            orderData.value.saleStartDate = shopData.value.saleStartDate

            orderData.value.bankName      = bankName.value
            orderData.value.account       = account.value
            orderData.value.accountName   = accountName.value
            //orderData.value.businessType  = shopData.value.businessType.value
            orderData.value.businessType  = "WHOLESALE"

            let od = JSON.parse(JSON.stringify(orderData.value));

            if (od.orderProduct && od.orderProduct.length > 0) {
                od.orderProduct.forEach(product => {
                    product.productSeq = product.seq
                    product.createUserId = orderData.value.name
                    product.modifyUserId = orderData.value.name

                    delete product.productImage
                    delete product.seq
                    delete product.productOption
                    delete product.deliveryAmount
                    delete product.sumAmount
                });
            }

            const res = await $axiosConnect(`/order`, 'POST', od)
            if(res && res.seq){
                alert("상품이 주문되었습니다. 감사합니다.")
            }else{
                alert("주문에 실패했습니다. 다시 시도해주세요.")
            }

            //폼 초기화
            productData.value   = {}
            orderData.value     = {}
            productTotal.value  = 0 
            deliveryTotal.value = 0 
            allTotal.value      = 0 

            onLoadSaleShop()
        }

        const isValidPhoneNumber = (phoneNumber) => {
            return /^0\d{8,12}$/.test(phoneNumber)
        }

        const onClickAccountClipboard = () => {
            Clipboard.copy(account.value);
        }

        return {
            websiteTitle,
            today,
            shopData,
            account,
            accountName,
            bankName,
            productData,
            orderData,

            productTotal,
            deliveryTotal,
            allTotal,

            formattedNotice,
            onClickquantityDown,
            onClickquantityUp,
            onChangequantity,
            onClickSubmit,
            onClickAccountClipboard
        }

    }
})
</script>
<style>
/*
@import "../assets/style/default.css";
@import "../assets/style/common.css";
@import "../assets/style/style.css";
*/
</style>
<style>
.v3dp__input_wrapper {
    position: relative;
}
.v3dp__clearable {
    position: absolute !important;
    right: 13px !important;
    left: auto !important;
    top: 10px !important;
}
</style>