import axios from 'axios';

export const createAxiosInstance = () => {
    const axiosInstance = axios.create({
        timeout     : 600 * 1000,
        crossdomain : true,
        withCredentials: false,
        headers     : {
            //'Content-Type': 'multipart/form-data; charset=UTF-8',
            'Content-Type': 'application/json; charset=UTF-8',
        }
    })
    return axiosInstance
};

export const responseHandler = (res) => {
    return new Promise((resolve, reject) => {
        let responseData = res.data;
        if (responseData) {
            resolve(responseData);
        } else {
            //reject(new Error(`STATUS ERROR`));
            reject
        }
    });
};

const httpRequest = {
    install(app) {
        app.config.globalProperties.$axiosConnect = (paramUrl, paramMethod, params = {}) => {
            const baseURL        = process.env.VUE_APP_BASE_API_URL
            const axiosInstance  = createAxiosInstance()
            return new Promise((resolve, reject) => {
                axiosInstance({
                    url    : `${baseURL}${paramUrl}`,
                    method : paramMethod,
                    data   : params
                })
                .then(res => responseHandler(res))
                .then(bodyData => {
                    resolve(bodyData);
                })
                .catch(error => {
                    reject(new Error(error))
                })
            })
        };
    }
};

export default httpRequest;
