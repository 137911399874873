<template>
    <header>
        <div class="logo_box">
            <h1 class="logo">
                <template v-if="userInfo.logo">
                    <img :src="userInfo.logo" height="100%" style="max-width: 110px;">
                </template>
                <template v-else>
                    {{ userInfo.name }}
                </template>
            </h1>
        </div>
    </header>
    
    <div class="container">
        <main class="card">
            <!-- begin: 공지 -->
            <div class="notice_box">
                <div class="notice_title">
                    <h2><i class="material-symbols-outlined"></i>Notice</h2> <strong class="today_box">{{ today }}</strong>
                </div>
                <div class="notice_content">
                    <div v-html="formattedNotice(shopData?.notice)"></div>
                </div>
            </div>
            <!-- end: 공지 -->
            
            <!-- begin : 상품정보 list -->
            <div class="product_ar">
                <h2>상품정보 List</h2>
                <ul class="product_list">
                    <li v-for="product in productData" :key="product.seq">
                        <div class="product_box">
                            <div class="product_box_set">
                                <h4 class="product_box_name">{{ product.name }} 
                                    <strong class="stockComment label label_red" 
                                        v-if="product.stockComment || product.inventory < 1" v-html="product.stockComment?product.stockComment:'<span class=stockNone>[품절]</span>'">
                                    </strong>
                                </h4>
                                <div class="product_box_price">{{ product.unitPrice.toLocaleString() }}원</div>
                            </div>
                            <div class="product_box_price">
                                <div class="product_box_count" :class="{'hidden': product.useInput, 'visible': !product.useInput}">
                                    <span>수량</span>
                                    <select v-model="product.quantity" @change="onChangequantity(product, 'select')">
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10+">10+</option>
                                    </select>
                                </div>
                                <div class="product_box_count" :class="{'hidden': !product.useInput, 'visible': product.useInput}">
                                    <span>수량</span>&nbsp;
                                    <input type="number" min="1" :max="product.inventory" v-model="product.quantity" @input="onChangequantity(product, 'input')">
                                </div>
                                <div class="product_box_price_inner">
                                    <div class="product_box_price_number">{{ product.amount.toLocaleString() }}원</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="order_total">
                <div class="order_box">
                    <div class="product_box_price_total">
                        <div class="product_box_price_total_inner">
                            <span>총 주문금액</span>
                            <strong class="product_box_price_total_number">{{ productTotal.toLocaleString() }}원</strong>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end : 상품정보 list -->
            
            <!-- begin : 주문서 작성 -->
            <div class="invoice_ar" style="position: relative;">
                <h2>주문정보 List</h2>
                <div style="position: absolute; right: 0; top: 42px;">
                    <input type="checkbox" id="invoice_check" v-model="isInvoiceCheck"> <label for="invoice_check">주문정보를 저장합니다.</label>
                </div>
                <div class="order_box">
                    <div class="order_input_box">
                        <form autocomplete="off">
                            <div class="tr_row">
                                <div class="tr_row_th"><strong>*</strong> <label>수령방법</label></div>
                                <div class="tr_row_td">
                                    <select class="product_delivery_way" v-model="deliveryType">
                                        <option v-for="(item, index) in deliveryTypeList" :key="index">{{ item.type }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th"><strong>*</strong> <label for="name">주문자(닉네임)</label></div>
                                <div class="tr_row_td"><input type="text" name="name" id="name" placeholder="주문자명을 입력하세요." v-model="orderData.name"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th"><strong>*</strong> <label for="phone">연락처</label></div>
                                <div class="tr_row_td"><input type="tel" name="phone" id="phone" placeholder="숫자만 입력. ex) 01012341111" v-model="orderData.phone"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th"><strong>*</strong> <label for="depositorName">입금자명</label></div>
                                <div class="tr_row_td"><input type="text" name="depositorName" id="depositorName" placeholder="입금자명을 입력하세요." v-model="orderData.depositorName"></div>
                            </div>
                            <div class="tr_row">
                                <div class="tr_row_th"><label for="memo">요청사항</label></div>
                                <div class="tr_row_td"><textarea name="memo" id="memo" placeholder="요청사항을 입력하세요." ref="memo" v-model="orderData.memo"></textarea></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end : 주문서 작성 -->
            
            <!-- begin : 입금계좌 안내 -->
            <div class="account_ar">
                <h2>입금계좌 안내</h2>
                <div class="account">
                    <form autocomplete="off">
                    <ul v-for="account in accountList" :key="account.seq">
                        <li><span class="label label_blue">입금계좌</span></li>
                        <li><span>{{ account.bankName }}<small>({{ account.accountName }})</small></span> <strong>{{ account.account }}</strong></li>
                        <li><button type="button" class="btn-clipboard" @click="onClickAccountClipboard(account.account)">계좌번호 복사</button></li>
                    </ul>
                    </form>
                </div>
            </div>
            <!-- end : 입금계좌 안내 -->
            

            <!-- begin : 주문하기 버튼-->
            <div class="order_btn_ar">
                <div class="total">
                    <small>총 결제금액</small><strong>{{ allTotal.toLocaleString() }}원</strong>
                </div>
                <button type="button"  class="order_btn" @click="onClickSubmit()">주문하기</button>
            </div>
            <!-- end : 주문하기 버튼 -->

        </main>
        <footer>
            <strong>상호명 : {{ userInfo.name }}</strong>
            <ul>
                <li v-if="userInfo.ceoName">대표이사 : {{ userInfo.ceoName }}</li>
                <li v-if="userInfo.address">주소: {{ userInfo.address }} {{ userInfo.addressDetail }}</li>
                <li v-if="userInfo.phone">Tel: {{ userInfo.phone }}</li>
                <li v-if="userInfo.businessNumber">사업자등록번호 : {{ userInfo.businessNumber }}</li>
                <li v-if="userInfo.mailOrderSalesRegistrationNumber">통신판매업신고 : {{ userInfo.mailOrderSalesRegistrationNumber }}</li>
                <li v-if="userInfo.businessNumber"><a :href="'https://www.ftc.go.kr/bizCommPop.do?wrkr_no='+onlyNumbers(userInfo.businessNumber)" target="_blank">사업자정보확인</a></li>
            </ul>
            <p>© 2024 골드오더</p>
        </footer>
    </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import * as dateUtils from '@/helper/utils/dateUtils.js'
import commonMixin from '@/helper/mixins/commonMixin.js'
import { Clipboard } from "v-clipboard";

export default defineComponent({
    name : 'shopPage',
    components: {},
    setup() {
        const { $axiosConnect, router } = commonMixin()

        const token           = ref(null)
        const websiteTitle    = ref(null)
        const today           = computed(() => dateUtils.convertDateToKoreanData())
        const shopData        = ref({})

        const accountList     = ref([])

        const productData     = ref({})
        const orderData       = ref({})

        const productTotal    = ref(0)
        const deliveryTotal   = ref(0)
        const allTotal        = ref(0)

        const deliveryTypeList = ref([])
        const deliveryType     = ref([])

        const userInfo         = ref({})

        const StockOver        = ref([])
        const StockNone        = ref([])

        const isInvoiceCheck   = ref(true)

        onMounted(() => {
            document.title = '주문은 여기를 클릭하세요.'

            // URL에서 토큰 값 추출
            const urlParams = new URLSearchParams(window.location.search)
            const urlToken  = urlParams.get('token')

            if(urlToken){
                token.value = urlToken
                onLoadSaleShop()
            }else{
                alert("접근이 제한되었습니다.")
                //window.history.go(-1)
                return
            }
        })

        const onLoadSaleShop = async () => {
            shopData.value = await $axiosConnect(`/sale/shop/${token.value}`, 'GET' )

            //과일가게-고정값
            //shopData.value.role          - 'ADMIN'
            //shopData.value.businessType  - 'SHOP'
            
            if(shopData.value.status === 'OFF'){
                alert("해당 상품의 판매가 종료되었습니다.")
                window.history.go(-1)
                return
            }

            if(shopData.value.businessType === 'SHOP'){
                //ownerUserId정보 가져오기 - 계좌번호, 은행 등
                const memberInfo = await $axiosConnect(`/api/member/id/${shopData.value.ownerUserId}`, 'GET')

                if(memberInfo){
                    accountList.value = memberInfo.body.list.memberAccount
                    userInfo.value  = memberInfo.body.list
                }
                websiteTitle.value = userInfo.value.name
                
                if(shopData.value.seq){
                    //상품정보 가져오기
                    try{
                    productData.value = await $axiosConnect(`/productInSale/get/${shopData.value.seq}`, 'GET')

                        //console.log(productData.value)

                        productData.value.forEach(item => {
                            item.quantity = 0 //주문갯수
                            item.amount   = 0 //주문갯수 * unitPrice = 주문금액

                            item.deliveryAmount = 0  //배송금액
                            item.sumAmount      = 0  //주문금액 + 배송금액
                            item.useInput       = false;    //SELECT <> INPUT

                            if(item.isUseStock === '1' || item.isUseStock === 1){
                                //item.inventory = item.goldCarriageAvailableStock - item.goldCarriageSaleCount   //재고
                                item.inventory = item.goldCarriageAvailableStock
                            }else{
                                item.inventory = 9999
                            }
                        })
                    }catch(error){
                        console.log(error)
                    }

                    //배송정보 가져오기
                    getDeliveryType()

                    //이전 저장 정보 가져오기
                    getReOderData()

                }else{
                    alert("상품을 가져오면서 오류가 발생했습니다.")
                    //window.history.go(-1)
                    return
                }

            }else{
                //alert("잘못된 접근입니다.")
                console.log("잘못된 접근입니다.")
                //window.history.go(-1)
                return
            }
        }

        const formattedNotice = (text) => {
            if (!text) return ''; // 빈 문자열인 경우 처리
            return text.replace(/\n/g, '<br>'); // 개행 문자(\n)를 HTML 줄 바꿈 태그(<br>)로 변환하여 반환
        }

        const onClickquantityDown = (product) => {
            if (product.quantity > 0) {
                product.quantity--
                onProductAmount(product)
            }
            
            onProductAllTotal()
        }

        const onClickquantityUp = (product) => {
            if (product.quantity >= 0) {
                if(product.inventory > 0 && product.inventory - product.quantity > 0){
                    product.quantity++
                    onProductAmount(product)
                }
            }
            onProductAllTotal()
        }

        const onChangequantity = (product, type) => {
            if (product.quantity === '10+') {
                product.useInput = true;
            } else {
                if(type === 'select'){
                    product.useInput = false;
                }
                if (product.quantity >= 0) {
                    if (product.inventory > 0 && product.inventory - product.quantity >= 0) {
                        onProductAmount(product)
                        onProductAllTotal()
                    } else {
                        alert("현재 주문 가능 수량은 " + product.inventory + "개 입니다.")
                        product.quantity = 0
                        return
                    }
                }
                if(product.quantity < 1){
                    product.quantity = 0
                    return
                }
            }
            
        }

        /*
         * 각 상품별 금액 계산
         */
         const onProductAmount = (product) => {
            //상품별 계산
                product.amount         = product.quantity * product.unitPrice
                //product.deliveryAmount = product.quantity > 0 ? product.deliveryFee : 0
                product.deliveryAmount = product.quantity > 0 ? product.deliveryFee * Math.ceil(product.quantity / 1) : 0
                product.sumAmount      = product.amount + product.deliveryAmount
        }

        /*
         * 총 결제 금액 계산
         */
        const onProductAllTotal = () => {
            productTotal.value = deliveryTotal.value = allTotal.value = 0
            productData.value.forEach(product => {
                productTotal.value  += product.amount
                deliveryTotal.value += product.deliveryAmount
                allTotal.value      += product.sumAmount
            })
        }

        const onClickSubmit = async () => {

            if(productTotal.value < 1){
                alert("주문하실 상품의 수량을 선택해주세요.")
                return
            }

            orderData.value.name          = orderData.value.name?.trim()
            orderData.value.address       = orderData.value.address?.trim()
            orderData.value.phone         = orderData.value.phone?.trim()
            orderData.value.memo          = orderData.value.memo?.trim()
            orderData.value.depositorName = orderData.value.depositorName?.trim()
            
            
            if (!orderData.value.name || 
                !orderData.value.phone || 
                !orderData.value.depositorName ||
                !deliveryType.value
            ) {
                //!orderData.value.address || 
                // || !orderData.value.payment
                    alert("주문서 정보를 입력해주세요.")
                    return;
            }

            if (!isValidPhoneNumber(orderData.value.phone)) {
                alert("유효한 전화번호를 숫자로 입력하세요.");
                return;
            }

            orderData.value.orderProduct  = productData.value.filter(prod => prod.amount > 0)
            orderData.value.amount        = allTotal.value  //총 결제금액
            orderData.value.token         = token.value
            orderData.value.status        = '주문'
            orderData.value.ownerId       = shopData.value.ownerUserId
            orderData.value.ownerName     = shopData.value.ownerUserName
            orderData.value.saleEndDate   = shopData.value.saleEndDate
            orderData.value.saleStartDate = shopData.value.saleStartDate
            /*
            orderData.value.bankName      = bankName.value
            orderData.value.account       = account.value
            orderData.value.accountName   = accountName.value
            */
            orderData.value.bankName      = ''
            orderData.value.account       = ''
            orderData.value.accountName   = ''
            
            orderData.value.deliveryType  = deliveryType.value
            orderData.value.businessType  = "SHOP"

            let od = JSON.parse(JSON.stringify(orderData.value));

            if (od.orderProduct && od.orderProduct.length > 0) {
                od.orderProduct.forEach(product => {
                    product.productSeq   = product.seq
                    product.createUserId = orderData.value.name
                    product.modifyUserId = orderData.value.name
                    product.productImage.forEach(productImage => {
                        productImage.createUserId = orderData.value.name
                        delete productImage.seq
                    })
                    product.orderProductImage = [...product.productImage]
                    if (product.productImage && Object.prototype.hasOwnProperty.call(product, 'productImage')) {
                        delete product.productImage
                    }
                    //배송방법 통일
                    product.deliveryType = deliveryType.value
                    
                    //delete product.isView;
                    delete product.seq;
                    delete product.productOption
                    delete product.deliveryAmount
                    delete product.sumAmount
                    delete product.styleClass
                    delete product.stockComment
                    delete product.useInput
                });
            }
            
            console.log("od:", od)
            
            const res = await $axiosConnect(`/order`, 'POST', od)

            console.log("res:", res)

            if(res && res.seq){
                //alert("상품이 주문되었습니다. 감사합니다.")
                sessionStorage.setItem("completed", JSON.stringify({ 
                    orderData: od,
                    token: token.value
                }))

                if(isInvoiceCheck.value === true){
                    //다음 주문에서 사용할 데이터를 스토리지에 담는다
                    localStorage.setItem("reOrderData", JSON.stringify({ 
                        "depositorName" : od.depositorName,
                        "memo"         : od.memo,
                        "name"         : od.name,
                        "phone"        : od.phone,
                        "deliveryType" : od.orderProduct[0].deliveryType
                    }))
                }else{
                    localStorage.removeItem("reOrderData");
                }

                //폼 초기화
                productData.value   = {}
                orderData.value     = {}
                productTotal.value  = 0 
                deliveryTotal.value = 0 
                allTotal.value      = 0 

                router.push('/SHOP/COMPLETED');
            }else{
                StockOver.value = []
                StockNone.value = []
                console.log("res:", res)

                res.orderProduct.forEach(product => {
                    if(product.isStock === 1){
                        //재고보다 주문수량이 많을때
                        StockOver.value.push(product)
                    }else if(product.isStock === 2){
                        //재고가 없음
                        StockNone.value.push(product)
                    }

                    if(product.isStock === 1 || product.isStock === 2){
                        //화면의 재고와 수량 바꾸기
                        const orgProduct = orderData.value.orderProduct.find(p => p.seq === product.productSeq);

                        //console.log("orderData.value.orderProduct:", orderData.value.orderProduct)
                        //console.log("orgProduct:", orgProduct)
                        //console.log("product.productSeq:", product.productSeq)

                        if (orgProduct) {
                            orgProduct.quantity                   = product.availableStock
                            orgProduct.goldCarriageAvailableStock = product.availableStock
                            orgProduct.inventory                  = product.availableStock
                            orgProduct.styleClass                 = 1
                            if(product.isStock === 1){
                                orgProduct.stockComment = "최대 주문 가능 수량 : " + product.availableStock
                            }else{
                                orgProduct.stockComment = "<span class=stockNone>[품절]</span>"
                            }
                        }
                    }
                })

                const StockOverName = StockOver.value.map(product => product.name).join(', ')
                const StockNoneName = StockNone.value.map(product => product.name).join(', ')

                let alertComment = "주문에 실패했습니다."
                if(StockOver.value.length > 0){
                    alertComment += "\r\n주문상품("+ StockOverName +")가 재고보다 주문수량이 많습니다."
                }
                if(StockNone.value.length > 0){
                    alertComment += "\r\n주문상품("+ StockNoneName +")의 주문이 가능하지 않습니다."
                }
                alertComment += "\r\n다시 주문해주세요."
                alert(alertComment)
            }
                

            //폼 초기화
            /*
            productData.value   = {}
            orderData.value     = {}
            productTotal.value  = 0 
            deliveryTotal.value = 0 
            allTotal.value      = 0 
            */

            //onLoadSaleShop()

        }

        const isValidPhoneNumber = (phoneNumber) => {
            return /^0\d{8,12}$/.test(phoneNumber)
        }

        const onClickAccountClipboard = (number) => {
            Clipboard.copy(number);
        }

        const getDeliveryType = async () => {
            const deliveryType = await $axiosConnect(`/api/deliveryType/list/${shopData.value.ownerUserId}`, 'GET')
            deliveryTypeList.value = deliveryType.body.list
        }

        const getReOderData = () => {
            const reOrderData = JSON.parse(localStorage.getItem("reOrderData"));
            if(reOrderData && reOrderData?.name){
                orderData.value.name          = reOrderData.name?.trim()
                orderData.value.phone         = reOrderData.phone?.trim()
                orderData.value.memo          = reOrderData.memo?.trim()
                orderData.value.depositorName = reOrderData.depositorName?.trim()
                deliveryType.value            = reOrderData.deliveryType?.trim()
            }
        }

        const onlyNumbers = (str) => {
            if(!str){
                return ''
            }
            return str.replace(/\D/g, '');
        }

        return {
            websiteTitle,
            today,
            shopData,
            accountList,
            productData,
            orderData,

            productTotal,
            deliveryTotal,
            allTotal,

            deliveryTypeList,
            deliveryType,

            userInfo,
            isInvoiceCheck,

            formattedNotice,
            onClickquantityDown,
            onClickquantityUp,
            onChangequantity,
            onClickSubmit,
            onClickAccountClipboard,
            onlyNumbers
        }

    }
})
</script>
<style scoped>
@import "../assets/style/style.css";
select {
    outline: none;
}
</style>