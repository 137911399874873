<template>
    <div>
        &nbsp;
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name : 'defaultHeaderPage',
    components: {},
    setup() {}
});
</script>