export const convertTimeToStr = (count) => {
    try {
        const minutes = Math.floor(count / 60);
        const seconds = Math.floor(count % 60); // 초를 정수형으로 변환하여 소수점 이하를 제거합니다.
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds; // 초를 2자리 숫자로 만듭니다.
        return `${minutes < 10 ? '0' : ''}${minutes}:${formattedSeconds}`;
    } catch (e) {
        return count;
    }
}

export const convertDateToKoreanData = () => {
    const date  = new Date()
    const year  = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day   = date.getDate().toString().padStart(2, '0')
    const days  = ['일', '월', '화', '수', '목', '금', '토']
    const dayOfWeek = days[date.getDay()]

    return `${year}년 ${month}월 ${day}일 (${dayOfWeek})`
}